<template>
  <b-overlay :show="isLoading">
    <form @submit.prevent="createLine()">
      <div class="mb-3">


        <div class="form-group">
          <label>name </label>
          <input type="text" :class="errors.name?'form-control is-invalid':'form-control'"
                 v-model="form.name">

          <div class="invalid-feedback" v-if="errors.name">
            <template v-for=" error in errors.name"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>icon </label>
          <input type="text" :class="errors.icon?'form-control is-invalid':'form-control'"
                 v-model="form.icon">

          <div class="invalid-feedback" v-if="errors.icon">
            <template v-for=" error in errors.icon"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>slug </label>
          <input type="text" :class="errors.slug?'form-control is-invalid':'form-control'"
                 v-model="form.slug">

          <div class="invalid-feedback" v-if="errors.slug">
            <template v-for=" error in errors.slug"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>url </label>
          <input type="text" :class="errors.url?'form-control is-invalid':'form-control'"
                 v-model="form.url">

          <div class="invalid-feedback" v-if="errors.url">
            <template v-for=" error in errors.url"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>ordre </label>
          <input type="text" :class="errors.ordre?'form-control is-invalid':'form-control'"
                 v-model="form.ordre">

          <div class="invalid-feedback" v-if="errors.ordre">
            <template v-for=" error in errors.ordre"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>isSu </label>
          <input type="text" :class="errors.isSu?'form-control is-invalid':'form-control'"
                 v-model="form.isSu">

          <div class="invalid-feedback" v-if="errors.isSu">
            <template v-for=" error in errors.isSu"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>creat_by </label>
          <input type="text" :class="errors.creat_by?'form-control is-invalid':'form-control'"
                 v-model="form.creat_by">

          <div class="invalid-feedback" v-if="errors.creat_by">
            <template v-for=" error in errors.creat_by"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>identifiants_sadge </label>
          <input type="text" :class="errors.identifiants_sadge?'form-control is-invalid':'form-control'"
                 v-model="form.identifiants_sadge">

          <div class="invalid-feedback" v-if="errors.identifiants_sadge">
            <template v-for=" error in errors.identifiants_sadge"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>entreprises </label>
          <CustomSelect
              :key="form.entreprise"
              :url="`${axios.defaults.baseURL}/api/entreprises-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.entreprise"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.entreprise_id=data.id;form.entreprise=data}"
          />
          <div class="invalid-feedback" v-if="errors.entreprise_id">
            <template v-for=" error in errors.entreprise_id"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>menus </label>
          <CustomSelect
              :key="form.menu"
              :url="`${axios.defaults.baseURL}/api/menus-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.menu"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.menu_id=data.id;form.menu=data}"
          />
          <div class="invalid-feedback" v-if="errors.menu_id">
            <template v-for=" error in errors.menu_id"> {{ error[0] }}</template>

          </div>
        </div>

      </div>

      <button type="submit" class="btn btn-primary">
        <i class="fas fa-floppy-disk"></i> Créer
      </button>
    </form>
  </b-overlay>
</template>

<script>
import Files from "@/components/Files.vue"

export default {
  name: 'CreateMenus',
  components: {CustomSelect: () => import("@/components/CustomSelect.vue"), Files},
  props: [
    'gridApi',
    'modalFormId',
    'entreprisesData',
    'menusData',
  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        id: "",

        name: "",

        icon: "",

        slug: "",

        url: "",

        ordre: "",

        isSu: "",

        menu_id: "",

        entreprise_id: "",

        creat_by: "",

        extra_attributes: "",

        created_at: "",

        updated_at: "",

        deleted_at: "",

        identifiants_sadge: "",
      }
    }
  },
  methods: {
    createLine() {
      this.isLoading = true
      this.axios.post('/api/menus', this.form).then(response => {
        this.isLoading = false
        this.resetForm()
        this.gridApi.applyServerSideTransaction({
          add: [
            response.data
          ],
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        icon: "",
        slug: "",
        url: "",
        ordre: "",
        isSu: "",
        menu_id: "",
        entreprise_id: "",
        creat_by: "",
        extra_attributes: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        identifiants_sadge: "",
      }
    }
  }
}
</script>
